module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"verbose":false,"url":"https://dev.webmobtech.biz/wordpress/graphql","html":{"useGatsbyImage":false,"createStaticFiles":false},"schema":{"typePrefix":"Wp","perPage":10,"timeout":1000000,"requestConcurrency":5},"develop":{"hardCacheMediaFiles":true},"production":{"hardCacheMediaFiles":true},"type":{"BlockEditorPreview":{"exclude":true},"UserRole":{"exclude":true},"UserPostFormatole":{"exclude":true},"ReusableBlock":{"exclude":true},"Comment":{"exclude":true},"TechStack":{"exclude":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"/blog"}],"excludePaths":["/blog","/blog/",{"regex":"/blog/category"},{"regex":"/blog/author"},{"regex":"/blog/tag"}],"height":3,"prependToBody":false,"color":"#335FFF","headerHeight":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#335FFF","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WebMob Technologies","short_name":"WMT","start_url":"/","background_color":"#F0F4FF","theme_color":"#335FFF","display":"standalone","icon":"src/assets/images/mainFest/MainFest_Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f16faefb3315c4eb9bbcedfbbbc82722"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
